$(document).ready(function () {
	window.addEventListener("load", function () {
		window.cookieconsent.initialise({
			"palette": {
				"popup": {
					"background": "#4b505a",
					"text": "#fff"
				},
				"button": {
					"background": "transparent",
					"text": "#fff",
					"border": "#fff",
					"border-radius": "none"
				}
			},
			"theme": "classic",
			"position": "top",
			"static": true,
			"type": "opt-in",
			"content": {
				"message": "Elevate uses cookies to provide you with a better online experience. To find out more about cookies, including what cookies have been set, ",
				"dismiss": "",
				"allow": "Accept",
				"link": "click here.",
				"href": "https://links.themyersbriggs.com/m88pzhz9"
			},
			"cookie": {
				"domain": ".themyersbriggs.com",
				"path": "/",
				"expiryDays": "365"
			}
		})
	});
});
